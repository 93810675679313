
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.WidgetWrapper {
  height: 100%;
  position: relative;

  &:empty {
    margin-top: 0;
  }

  &:first-child:empty {
    margin-bottom: spacing(-4);
  }
}

@include mq($max: $breakpoint-tablet - 1) {
  .hidden-mobile {
    display: none;
  }
}

@include mq($breakpoint-tablet, $max: $breakpoint-desktop - 1) {
  .hidden-tablet {
    display: none;
  }
}

@include mq($breakpoint-desktop) {
  .hidden-desktop {
    display: none;
  }
}

.sticky {
  height: auto;
  margin-top: spacing(3);
  @include mq($breakpoint-desktop) {
    position: sticky;
    top: 210px;
  }

  &:first-child {
    margin-top: 0 !important;
  }
}

.Anchor {
  position: absolute;
  top: -100px;
  @include mq($breakpoint-desktop) {
    top: -210px;
  }
}
